<template>
  <div>
    <div
      class="text-center"
      v-if="isLoading"
    >
      <v-progress-circular
        class="text-center"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-if="!isLoading">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h1 class="pb-5">Улицы</h1>
            <v-card outlined>
              <v-card-text>
                <v-checkbox
                  class="mb-3"
                  v-model="isVerified"
                  label="Подтверждена"
                ></v-checkbox>
                <v-radio-group
                  v-model="mainStreetIndex"
                  :mandatory="false"
                  :key="detail.length"
                >
                  <div
                    class="mb-3"
                    min-height="10px;"
                    v-for="(itemDetail, i) in detail"
                    :key="i"
                  >
                    <v-row class="align-center">
                      <v-col cols="2">
                        <v-radio :value="i"></v-radio>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          label="Улица"
                          v-model="itemDetail.name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-btn
                          v-if="i > 0"
                          :disabled="detail.length < 2"
                          icon
                          @click="handleRemoveStreet(i)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-radio-group>
                <v-btn
                  class="primary"
                  @click="handleAddStreet"
                >
                  <v-icon
                    small
                    class="mr-2"
                  >mdi-plus
                  </v-icon>
                  Добавить синоним
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <div class="text-right saveBlock">
        <v-btn
          small
          dark
          class="mr-2"
          color="light-green"
          @click="saveDetail"
          :loading="isSavingData"
        >
          <v-icon class="pr-3">mdi-content-save</v-icon>
          Сохранить
        </v-btn>
        <v-btn
          v-if="detail[0].id"
          class="mr-2"
          dark
          small
          @click.stop="handleSafeDelete"
        >
          <v-icon class="pr-3">mdi-delete</v-icon>
          Удалить улицу
        </v-btn>
        <SafeDelete
          v-if="value.requestParams.id"
          :confirm-delete.sync="confirmDelete"
          :can-delete="canDelete"
          :filter="{ streetFilter: {id: this.detail[0].parent_id, name: this.detail[0].name}}"
          category="building"
          :checking-delete="checkingDelete"
          @delete="deleteDetail"
        />
      </div>
    </div>
  </div>
</template>

<script>
import detail from '@/components/mixins/detail'
import Autocomplete from '@/components/views/admin/common/Autocomplete'
import Street from '@/components/structures/street'
import SafeDelete from '@/components/views/admin/common/SafeDelete'

export default {
  components: { SafeDelete, Autocomplete },
  mixins: [detail],
  name: 'Detail',
  data: () => ({
    componentCategory: 'street',
    mainIndex: 0
  }),
  computed: {
    mainStreetIndex: {
      get () {
        return this.mainIndex
      },
      set (value) {
        if (value !== undefined) {
          this.handleMainIndexChange(this.mainIndex, value)
          this.mainIndex = value
        }
      }
    },
    isVerified: {
      get () {
        return this.detail[0].is_verified
      },
      set (val) {
        this.detail.forEach(street => {
          street.is_verified = val
        })
      }
    }
  },
  watch: {
    isLoading () {
      this.setMainIndex()
    }
  },
  methods: {
    handleRemoveStreet (index) {
      this.detail.splice(index, 1)
      this.setMainIndex()
      this.handleMainIndexChange(this.mainIndex, this.mainIndex)
    },
    handleAddStreet () {
      const street = new Street()
      street.is_main = false
      street.is_verified = this.isVerified
      this.detail.push(street)
    },
    handleMainIndexChange (oldIndex, newIndex) {
      this.detail[oldIndex].is_main = 0
      this.detail[newIndex].is_main = 1
    },
    setMainIndex () {
      this.mainIndex = 0
      this.detail.forEach((item, index) => {
        if (item.is_main) {
          this.mainIndex = index
        }
      })
    },
    async saveDetail () {
      this.detail = await this.$store.dispatch('detail/save', {
        url: '/' + this.componentCategory + '/detail/',
        data: this.detail
      })
      if (this.detail.length) {
        this.value.requestParams.id = this.detail[0].parent_id
      }
    }
  }
}
</script>

<style scoped>
</style>
