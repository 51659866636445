<template>
  <v-dialog
    :value="confirmDelete"
    max-width="590"
  >
    <v-card
      outlined
      class="pa-5"
    >
      <v-card-title class="headline">Подтверждение удаления записи</v-card-title>
      <v-card-text v-if="!canDelete">
        <p> Эта запись используется используется в...
          <v-btn @click="handleDeleteList">
            Посмотреть список
          </v-btn>
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          @click="handleDialogOpen(false)"
        >
          Отменить
        </v-btn>
        <v-btn
          class="primary"
          :disabled="!canDelete"
          small
          @click="$emit('delete')"
          :loading="checkingDelete"
        >
          Удалить.
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'SafeDelete',
  props: {
    confirmDelete: Boolean,
    category: String,
    filter: Object,
    canDelete: Boolean,
    checkingDelete: Boolean
  },
  data: () => ({}),
  computed: {
    paramsToCheckDelete () {
      const requestParams = {
        'filters': {
          'isVerified': null
        },
        'options': {
          'page': 1,
          'itemsPerPage': 20,
          'sortBy': [],
          'sortDesc': [],
          'groupBy': [],
          'groupDesc': [],
          'mustSort': false,
          'multiSort': true
        }
      }

      requestParams.filters = { ...requestParams.filters, ...this.filter }
      return requestParams
    }
  },
  methods: {
    handleDialogOpen (data) {
      this.$emit('update:confirmDelete', data)
    },
    handleDeleteList () {
      this.add(this.category, 'list', { requestParams: this.paramsToCheckDelete })
      this.handleDialogOpen(false)
      this.$router.push({ name: this.category })
    },
    add (categoryName, type, data) {
      this.$store.commit('tab/add', { categoryName, type, data })
    }
  }
}
</script>

<style scoped>

</style>
